@use 'ui/styles/variables' as vars;

$default-height: 150px !default;
$default-width: 500px !default;

.Container {
  display: flex;
  justify-content: center;
}

.Carousel {
  padding: 16px 48px;
  height: $default-height;
  width: $default-width;
  align-items: center;
  overflow: hidden;
  display: flex;
}

.NavButton {
  border: none !important;
  background-color: transparent !important;
}